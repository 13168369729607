import React, { useState, useEffect, createContext, lazy } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Navigate,
} from 'react-router-dom'
import { ConfigProvider, message } from 'antd'
import zh_cn from 'antd/locale/zh_CN'
import eng from 'antd/locale/en_US'
import 'dayjs/locale/zh-cn'
import { IntlProvider } from 'react-intl'
import zh_CN from './static/locale/zh-CN'
import en_US from './static/locale/en-US'
import PrivateRoute from './pages/PrivateRoute/privateRoute'
// import { HTML5Backend } from 'react-dnd-html5-backend'
// import { DndProvider } from 'react-dnd'
import './App.css'
import http from './utils/http'

const Main = lazy(() => import('./pages/main/main'))
const Login = lazy(() => import('./pages/login/login'))
const AiTestRuns = React.lazy(() => import('./pages/aiTestRuns/index'))

export const myContext = createContext({
  locale: '',
  languageChange: () => {},
  onLogin: () => {},
})

function App() {
  const [locale, setLocale] = useState('en')
  const [auth, setAuth] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    let lang = ''
    if (localStorage.getItem('locale')) {
      lang = localStorage.getItem('locale')
    } else {
      lang = 'zh'
      localStorage.setItem('locale', lang)
    }
    if (lang === 'zh') {
      setLocale('en')
      // moment.locale('zh-cn')
    } else {
      setLocale('en')
      // moment.locale('en')
    }
  }, [])

  const languageChange = (lang) => {
    setLocale(lang)
    localStorage.setItem('locale', lang)
  }

  const onLogin = (obj) => {
    if (obj) {
      http
        .post('/api/auth/login', obj)
        .then((res) => {
          setAuth(obj ? true : false)
          navigate('/home')
          localStorage.setItem('auth', obj ? true : false)
        })
        .catch((res) => {
          message.error(res.response.data.msg)
        })
    }
  }
  const contextVal = {
    languageChange,
    locale,
    onLogin,
  }

  const messages = {
    en: en_US,
    zh: zh_CN,
  }
  return (
    //  <DndProvider backend={HTML5Backend}>
    //     <Card />
    //   </DndProvider>

    <IntlProvider
      locale={locale === 'zh' ? 'zh' : 'en'}
      messages={messages[locale]}
    >
      <ConfigProvider locale={locale === 'zh' ? zh_cn : eng}>
        <myContext.Provider value={contextVal}>
          <Routes>
            <Route element={<PrivateRoute authed={auth} />}>
              <Route path="/" element={<Navigate to="/home" />} />
              <Route path="/*" element={<Main />} />
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="/aiTestRuns" element={<AiTestRuns />} />
          </Routes>
        </myContext.Provider>
      </ConfigProvider>
    </IntlProvider>
  )
}

export default App
